<script setup lang="ts">
import { fbInit } from 'auth/integrations/facebook'
import { googleInit, initBtn } from 'auth/integrations/google'
import IconFacebookColor from '@/assets/images/facebook-color.svg'

const runtimeConfig = useRuntimeConfig()
const isHttps = ref(false)
const container = ref<HTMLElement | null>(null)
const isFacebookAvailable = computed(
  () => !!runtimeConfig.public.fbAppId && isHttps.value,
)
const isFBReady = ref(false)
const isGoogleAvailable = computed(
  () => !!runtimeConfig.public.gClientId && isHttps.value,
)

onMounted(() => {
  isHttps.value = location.protocol === 'https:'
  if (isFacebookAvailable.value) {
    window.addEventListener(
      'fb-sdk-ready',
      () => {
        isFBReady.value = true
      },
      { once: true },
    )
    fbInit()
  }
  if (isGoogleAvailable.value) {
    googleInit()
    useResizeObserver(
      container,
      useDebounceFn(() => {
        initBtn()
      }, 200),
    )
  }
})

const { $notify } = useNuxtApp()
const authSocialLoginStore = useAuthSocialLoginStore()

const signInWithFacebook = () => {
  FB.getLoginStatus(({ authResponse, status }) => {
    if (status === 'connected') {
      authSocialLoginStore.loginWithFacebook(authResponse)
    } else {
      FB.login(
        (response) => {
          if (response.authResponse?.accessToken) {
            authSocialLoginStore.loginWithFacebook(response.authResponse)
          } else {
            $notify({
              text: 'Facebook - Login error',
              severity: 'error',
            })
          }
        },
        { scope: 'email,public_profile' },
      )
    }
  })
}
</script>

<template>
  <div
    ref="container"
    class="flex w-full flex-col"
  >
    <!-- Google -->
    <div
      v-if="isGoogleAvailable"
      id="google-sign-in-btn"
      class="h-[60px] w-full"
    />
    <!-- Facebook -->
    <Button
      v-if="isFacebookAvailable"
      type="button"
      :disabled="!isFBReady"
      class="p-button-outlined w-full rounded !border-[#dadce0] px-2 py-1.5 disabled:cursor-progress"
      severity="secondary"
      outlined
      @click="signInWithFacebook"
    >
      <IconFacebookColor
        aria-hidden="true"
        class="h-7 w-7"
      />
      <span class="w-full font-normal text-[#3c4043]">
        Continue with Facebook
      </span>
    </Button>
  </div>
  <Divider
    v-if="isGoogleAvailable || isFacebookAvailable"
    align="center"
    class="!my-4"
  >
    OR
  </Divider>
</template>
