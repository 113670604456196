import { useAuthSocialLoginStore } from 'auth/store/social-login'

export function googleInit() {
  const scriptsLocation = document.querySelector('script')
  if (document.querySelector('#google-sign-in')) {
    initBtn()
    return
  }
  const script = document.createElement('script')
  script.id = 'google-sign-in'
  script.src = '//accounts.google.com/gsi/client'
  scriptsLocation?.parentNode?.insertBefore(script, scriptsLocation)

  script.addEventListener(
    'load',
    () => {
      const runtimeConfig = useRuntimeConfig()
      const authSocialLoginStore = useAuthSocialLoginStore()
      window.google.accounts.id.initialize({
        client_id: runtimeConfig.public.gClientId,
        callback: authSocialLoginStore.loginWithGoogle,
      })
      initBtn()
      window.google.accounts.id.prompt() // also display the One Tap dialog
    },
    { once: true },
  )
}

export function initBtn() {
  const btn = document.querySelector('#google-sign-in-btn') as HTMLElement

  if (!btn) {
    return
  }

  btn.innerHTML = ''
  window.google?.accounts.id.renderButton(btn, {
    type: 'standard',
    text: 'continue_with',
    width: btn.clientWidth,
    locale: 'en_US',
    logo_alignment: 'left',
  })
}
